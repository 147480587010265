/* Slide in animation for paragraphs */
.slide-in {
    animation: slideIn 1s ease-out forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Animation for letter-by-letter */
  .letter {
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Add delay for each letter */
  .h1-wrapper {
    display: inline-block;
  }
  
  .h1-wrapper span {
    animation: fadeInUp 0.5s forwards;
    display: inline-block;
  }
  
.image-panel {
  width: 150%;
  /* max-width: 500px; or any other max-width */
  height: auto;
  transition: transform 0.3s, box-shadow 0.3s;
}

.image-head {
  width: 10%;
  max-width: 500px; /* or any other max-width */
  height: auto;
  transition: transform 0.3s, box-shadow 0.3s;
}

@media (max-width: 600px) {
  .image-panel,
  .image-head {
    width: 40%; /* Resizes to 50% on smaller screens */
  }
}

.image-content {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  transition: transform 0.3s, box-shadow 0.3s;
}
