/* Fashion.css */

/* General styles */
.clothing-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.vintage-header {
  font-family: 'Courier New', Courier, monospace;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.clothing-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.clothing-item {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.clothing-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

/* Mobile styles */
/* @media (max-width: 768px) {
  .clothing-grid {
    grid-template-columns: 1fr;
  }
} */

.contact-section {
  margin-top: 30px;
}

.contact-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.contact-button:hover {
  background-color: #333;
}
