/* Slide in animation for paragraphs */
.slide-in {
    animation: slideIn 1s ease-out forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Animation for letter-by-letter */
  .letter {
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Add delay for each letter */
  .h1-wrapper {
    display: inline-block;
  }
  
  .h1-wrapper span {
    animation: fadeInUp 0.5s forwards;
    display: inline-block;
  }
  
  .vignette-text {
    position: relative;
    display: inline-block;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  /* Larger vignette for <p> elements */
  .vignette-text.paragraph::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.7), transparent 80%);
    pointer-events: none;
    z-index: -1;
    border-radius: 15px;
  }
  
  