/* Slide in animation for paragraphs */
.slide-in {
    animation: slideIn 1s ease-out forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Animation for letter-by-letter */
  .letter {
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Add delay for each letter */
  .h1-wrapper {
    display: inline-block;
  }
  
  .h1-wrapper span {
    animation: fadeInUp 0.5s forwards;
    display: inline-block;
  }
  
  .vignette-text {
    position: relative;
    display: inline-block;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .vignette-text::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.7), transparent 30%);
    pointer-events: none;
    z-index: -1;
    border-radius: 5px;
  }

  .imageContainer {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), inset 0 0 30px rgba(0, 0, 0, 0.7);
}

.imageContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    pointer-events: none;
    z-index: 1;
    background: radial-gradient(circle at center, rgba(0, 0, 0, 0.7), transparent 80%);
}

  