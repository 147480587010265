.audio-player {
    background-color: #00000000;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
    /* border-radius: 10px; */
    max-width: 400px;
    margin: 5px auto;
    /* border: 2px solid #444; */
    font-family: 'Courier New', Courier, monospace; /* Old-school font */
  }
  
  .play-pause-btn,
  .stop-btn {
    background-color: #ff000000;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 1.2em;
    /* border-radius: 5px; */
  }
  
  .play-pause-btn:hover,
  .stop-btn:hover {
    background-color: #cc00001a;
  }
  
  .track-info {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    margin-right: 10px;
  }
  
  .volume-control {
    cursor: pointer;
    width: 100px;
  }
  