body {
  background: url('/src/imgs/image2.jpg') repeat;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  cursor: url('/src/imgs/thingy3.png') 16 16, auto;
  color: white; /* Set the font color to white */
  text-align: center; /* Center all text */
  padding-bottom: 80px;
}

.App {
  position: relative;
  min-height: 100vh;
  background-color: transparent;
  color: white; /* Ensure the text is white */
  text-align: center;
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Send video to the background */
}

.vignette-text {
  position: relative;
  display: inline-block;
}

.vignette-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.7), transparent 70%);
  pointer-events: none;
  z-index: -1;
}



header {
  position: relative;
  z-index: 1;
}

header img {
  margin: 0 auto;
}

a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: red; /* Change to red on hover */
  text-decoration: underline;
}

#bio {
  margin: 20px auto;
  max-width: 600px; /* Limit the width of the text for better readability */
}

#contact {
  margin-top: 40px;
  font-size: 1.2em;
}

footer {
  padding: 20px;
  background-color: #000;
  text-align: center;
  color: white;
  position: relative;
  z-index: 1; /* Ensure footer appears above video */
  margin-top: auto;
  padding-bottom: 50px; /* Ensure the footer doesn't touch the edge */
}

/* Slide in animation for paragraphs */
.slide-in {
  animation: slideIn 1s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Animation for letter-by-letter */
.letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add delay for each letter */
.h1-wrapper {
  display: inline-block;
}

.h1-wrapper span {
  animation: fadeInUp 0.5s forwards;
  display: inline-block;
}

.image-panel,
.image-head {
  width: 100%;
  max-width: 500px; /* or any other max-width */
  height: auto;
  transition: transform 0.3s, box-shadow 0.3s;
}

@media (max-width: 600px) {
  .image-panel,
  .image-head {
    width: 50%; /* Resizes to 50% on smaller screens */
  }
}

.image-content {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  transition: transform 0.3s, box-shadow 0.3s;
}
